//=================================================================================
var default_width = window.innerWidth;//アクセスした時の横幅を取得 スクロールバーを含めた値
var resize_width = window.innerWidth;
var break_point = 1024;
$(function() {
	var default_height = $(document).innerHeight();//アクセスした時の高さを取得
  /*------------------------*/
  //リンクの範囲を広くする
  /*------------------------*/
  $(".widelink").on('click',function(){
    window.location=$(this).find("a").attr("href");
    return false;
	});
	barFix();//追従処理

  /*------------------------*/
  //テキストが多すぎる場合
  /*------------------------*/
    var count = 60;
    $('.text_overflow').each(function() {
        var thisText = $(this).text();
        var textLength = thisText.length;
        if (textLength > count) {
            var showText = thisText.substring(0, count);
            var hideText = thisText.substring(count, textLength);
            var insertText = showText;
            insertText += '<span class="hide">' + hideText + '</span>';
            insertText += '<span class="omit">…</span>';
            insertText += '<a href="" class="more">もっと見る</a>';
            $(this).html(insertText.replace(/\r?\n/g, '<br>'));
        };
    });
    $('.text_overflow .hide').hide();
    $('.text_overflow .more').on('click',function() {
        $(this).hide()
            .prev('.omit').hide()
            .prev('.hide').fadeIn();
        return false;
    });


	/*------------------------*/
  //ページトップ
  /*------------------------*/
	var topBtn = $('.pageTop');
  if (default_width >= break_point) {
    topBtn.hide();
  }
	// スクロールが200に達したらボタン表示
	$(window).scroll(function () {
		if ($(this).scrollTop() > 200) {
				topBtn.fadeIn();
		} else if ($(this).scrollTop() < 200) {
				topBtn.fadeOut();
		} else {
      topBtn.fadeIn();
    }
	});
	// スクロールしてトップ
	topBtn.on('click',function () {
		$('body,html').animate({
				scrollTop: 0
		}, 500);
		return false;
  });

  //追従バナー
  const banner = $(".fixedBtn").not(".bengo");
  const bannerBengo = $(".fixedBtn.bengo");
  if (banner.length) {
    $(window).on('scroll',function () {
      let doch = $(document).innerHeight(), //ページ全体の高さ
      winh = $(window).innerHeight(), //ウィンドウの高さ
      bottom = doch - winh, //ページ全体の高さ - ウィンドウの高さ = ページの最下部位置
      footh = $(".footer").innerHeight();
      if ($(this).scrollTop() > window.innerHeight * 1.5 && $(this).scrollTop() < bottom - footh) {
        banner.fadeIn().removeClass("static").addClass("fixed");
      } else if (($(this).scrollTop() < window.innerHeight * 1.5)) {
        banner.removeClass("fixed");
      } else {
        banner.removeClass("fixed").addClass("static");
      }
    });
  } else if (bannerBengo.length) {
    $(window).on('scroll',function () {
      let doch = $(document).innerHeight(), //ページ全体の高さ
      winh = $(window).innerHeight(), //ウィンドウの高さ
      bottom = doch - winh, //ページ全体の高さ - ウィンドウの高さ = ページの最下部位置
      footh = $(".footer").innerHeight();
      if (default_width <= break_point) {
        if ($(this).scrollTop() > window.innerHeight * 1.5 && $(this).scrollTop() < bottom - footh) {
          bannerBengo.fadeIn().removeClass("static").addClass("fixed");
        } else if (($(this).scrollTop() < window.innerHeight * 1.5)) {
          bannerBengo.removeClass("fixed");
        } else {
          bannerBengo.removeClass("fixed").addClass("static");
        }
      }
    });
  }

  //スムーススクロール（ページ内リンク）
  $('.tocList__item a[href^="#"],.js-scroll').on("click", function () {
    var f = 600;
    var e = $(this).attr("href");
    var g = $(e == "#" || e == "" ? "html" : e);
    var d = g.offset().top;
    $("html, body").animate({
      scrollTop: d
    }, f, "swing");
    return false
  });

  //目次開閉
  var flg = "open";
  $(".toc_toggle").on('click',function(){
    $(".tocList").slideToggle();
    if (flg == "close") {
      $(this).text("[非表示]");
      flg = "open";
    } else {
      $(this).text("[表示]");
      flg = "close";
    }
  });

  //エリアから事務所を探す
	$(".areaSearch .areaList__item .areaSearch__subTtl").on('click',function(){
    if (default_width <= break_point) {
      $(this).parent().addClass("on");
      $(".areaSearch").addClass("on");
      $(".areaSearch .areaList__item").not($(this).parent()).addClass("off");
    }
	});
	$(".areaSearch .back").on('click',function() {
    if (default_width <= break_point) {
  		$(".areaSearch .areaList__item").removeClass("on off");
      $(".areaSearch").removeClass("on");
    }
  });

  //エリアから事務所を探す（アコーディオン）
  $('.areaSearch02 .areaList__item .areaSearch__subTtl').on('click', function() {
    if (default_width <= break_point) {
      //クリックされた要素に隣接する.childrenをtoggle
      if ($(this).parent(".areaList__item").hasClass("firstShow")) {
        $(this).next('.children').slideToggle();
        $(this).parent(".areaList__item").removeClass("firstShow");
      } else {
        $(this).next('.children').slideToggle();
        $(this).parent(".areaList__item").toggleClass("open");
        //クリックされたアコーディオン以外を閉じる
        // $('.areaSearch02 .areaList__item .areaSearch__subTtl').not($(this)).next('.children').slideUp();
        // $('.areaSearch02 .areaList__item .areaSearch__subTtl').not($(this)).parent(".areaList__item").removeClass("open firstShow");
      }
    }
  });
  if (default_width <= break_point) {
    if ($(".areaSearch02 .areaList__item .areaSearch__subTtl").parent(".areaList__item").hasClass("firstShow")) {
      $(".firstShow").children('.children').show();
    }
  }

  //フッターメニュー（アコーディオン）
  $('.footerCont01 .fNav__ttl').on('click', function() {
    if (default_width <= break_point) {
      //クリックされた要素に隣接する.childrenをtoggle
      $(this).next('.list').slideToggle();
      //クリックされたアコーディオン以外を閉じる
      $('.footerCont01 .fNav__ttl').not($(this)).next('.list').slideUp();
    }
  });

  //tab
  $('.tabMenu__item').on('click',function() {
    var i = $('.tabMenu__item').index(this);
    // クリック済みタブのデザインを設定したcssのクラスを一旦削除
    $('.tabMenu__item').removeClass('active');
    // クリックされたタブにクリック済みデザインを適用する
    $(this).addClass('active');
    // コンテンツを一旦非表示にし、クリックされた順番のコンテンツのみを表示
    $('.tabItem .list').fadeOut(200).removeClass("show").eq(i).fadeIn(200).addClass("show");
  });

  //modal
  $('.js-modalSwitch').on('click',function() {
    $('#modalArea').fadeIn();
  });
  $('#closeModal , #modalBg').click(function(){
    $('#modalArea').fadeOut();
  });
  /*----------------------------------------------------*/
  /* [PC][SP]UseragentでJSを切り替え
  /*----------------------------------------------------*/
  var getDevice = (function() {
  var ua = navigator.userAgent;
    if (!(ua.indexOf('iPhone') > 0 || ua.indexOf('iPod') > 0 || ua.indexOf('Android') > 0 && ua.indexOf('Mobile') > 0)) {
      /*-------------------------------------------------------------------------*/
      /* PCの時
      /*-------------------------------------------------------------------------*/
      $(".widelink").hover(
        function(e) {
            $(this).addClass("hover");
        },
        function(e) {
            $(this).removeClass("hover");
        }
      );
    }
  })();

	/*------------------------*/
	//バーガーメニュー表示・非表示
	/*------------------------*/
	//バーガーボタンクリック
	$(".burger").on('click',function() {
		default_width = window.innerWidth;//クリックされた時の数値をdefault_widthに設定
		if (default_width <= break_point) {
      $(".burger").toggleClass("is-open");
      if ($(".gNav").hasClass("show")) {
        $(".gNav").removeClass("show").addClass("hide");
      } else if ($(".gNav").hasClass("hide")) {
        $(".gNav").removeClass("hide").addClass("show");
      } else {
        $(".gNav").addClass("show");
      }
			if ($(this).hasClass("is-open")) { //バーガーメニューが開かれた状態か
        $(".headerNav__cover").height(default_height+45+"px").removeClass("hide").addClass("show");
			} else {
        $(".headerNav__cover").height(0).addClass("hide").removeClass("show");
			}
		}
	});
  //カバーをクリック
	$(".headerNav__cover,.gNav a").on('click',function() {
    if (default_width <= break_point) {
  		$(".gNav").removeClass("show").addClass("hide");
  		$(".burger").removeClass("is-open");
      $(".headerNav__cover").addClass("hide").removeClass("show");
    }
	});

	$(".pullDownHover").on('click',function(){
		if (default_width <= break_point) {
			$(this).toggleClass('active');
			$(".pullDown").slideToggle(400);
		}
	});

	$('.js--pd--sp').each(function() {
		$(this).css("height",$(this).height()+"px");
	});
	$('.js--pd--sp__switch').on('click',function () {
		if (resize_width <= break_point) {
			$('.js--pd--sp').css("height","auto");
			$(this).find('.js--pd--sp').slideToggle('200');
			$('.js--pd--sp__switch').not($(this)).removeClass('active');
			$('.js--pd--sp__switch').not($(this)).find('.js--pd--sp').slideUp('200');
			$(this).toggleClass('active');
		}
	});

	/*------------------------*/
	//リサイズ時の処理（リサイズした瞬間に走る）
	/*------------------------*/
	$(window).on('resize',function() {
		if (default_width == window.innerWidth){//アクセスした時と、リサイズした時のウィンドウ幅が同じかどうかを判定
			return;//アクセスした時と、リサイズした時のウィンドウ幅が同じだった時に処理を抜ける
		} else {
			default_width = window.innerWidth;//リサイズ処理がされた場合、default_widthの数値を更新
			resize_width = window.innerWidth;//リサイズが完了したらウィンドウサイズの更新
			if (resize_width <= break_point) {
				/*------------------------*/
				//スマホ処理（リサイズ時）
				/*------------------------*/
        //バーガーメニューを開いたままリサイズされた時の処理
        $(".pullDown").css("display","none");
        $(".gNav").removeClass("show hide");
				$(".burger").removeClass("is-open");
				$(".headerNav__cover").height(0).addClass("hide").removeClass("show");
				//アコーディオンメニュー非表示
        $('.js--pd--sp').hide();
        //エリアから事務所を探す非表示
        $('.areaSearch02 .areaList__item').not(".firstShow").children(".children").hide();
        //フッターメニュー非表示
        $('.footerCont01 .list').hide();
			} else {
				/*------------------------*/
				//PC処理（リサイズ時）
				/*------------------------*/
        $(".pullDown").css("display","block");
        $(".gNav").removeClass("show hide");
        //エリアから事務所を探す（アコーディオン）
        $('.areaSearch02 .areaList__item .children').css("display","flex");
        $('.areaSearch02 .areaList__item').removeClass("open");
        //フッターメニュー（アコーディオン）
        $('.footerCont01 .list').css("display","flex");
			}
		}
	});

	/*------------------------*/
	//リサイズ完了時点処理（完了するまで処理されない）
	/*------------------------*/
	var timer = false;
	$(window).on('resize',function() {
		if (timer !== false) {
				clearTimeout(timer);
		}
		timer = setTimeout(function() {
			barFix(); //追従処理
			resize_width = $(window).innerWidth();//リサイズが完了したらウィンドウサイズの更新
			if (resize_width <= break_point) {
				/*------------------------*/
				//スマホ処理（リサイズ完了時）
				/*------------------------*/
			} else {
				/*------------------------*/
				//PC処理（リサイズ完了時）
				/*------------------------*/
				$('.js--pd--sp').show();
			}
		}, 200);
	});
});


/*------------------------*/
//関数
/*------------------------*/
/*トップページ　スライダー*/
var sliderBelongs = function() {
	$(function() {
		//slider設置
    var $slider = $('.slider--belongs');
    var slideCount = $('.slider--belongs .list__item').length;
    if (slideCount >= 3) {
      $slider.slick({
        accessibility: false,
        dots: true,
        slidesToShow: 3,
        draggable: false,
        responsive: [
          {
            breakpoint: 1024,//ブレイクポイントを指定
            settings: {
              slidesToShow: 1,
              draggable: true,
              centerMode: true,
              centerPadding:'20%'
            }
          }
        ]
      });
    }
	});
}

/*事務所詳細　スライダー*/
var sliderArchive = function() {
	$('.sliderArchive').slick({
		dots:true,
		prevArrow: '<a class="slick-prev" href="#"><i data-icon="ei-arrow-left" data-size="m"></i></a>',
		nextArrow: '<a class="slick-next" href="#"><i data-icon="ei-arrow-right" data-size="m"></i></a>',
		customPaging: function(slick,index) {
			var targetImage = slick.$slides.eq(index).find('img').attr('src');
			return '<img src=" ' + targetImage + ' "/>';
    },
    responsive: [
      {
        breakpoint: 1024,//ブレイクポイントを指定
        settings: {
          dots:true,
          slidesToShow: 1,
          draggable: true
        }
      }
    ]
	});
}

var barFix = function() {
	//該当のセレクタなどを代入
	var mainArea = $(".layout--2col__cont"); //メインコンテンツ
	var sideWrap = $(".sideNav"); //サイドバーの外枠
	var sideArea = $(".sideNav__inner"); //サイドバー
	var wd = $(window); //ウィンドウ自体

	//メインとサイドの高さを比べる 
	var mainH = mainArea.innerHeight();
	var sideH = sideWrap.innerHeight();
	if(sideH < mainH && default_width >= break_point) { //メインの方が高ければ色々処理する
		//サイドバーの外枠をメインと同じ高さにしてrelaltiveに（#sideをポジションで上や下に固定するため）
		sideWrap.css({"height": mainH,"position": "relative"});
		//サイドバーがウィンドウよりいくらはみ出してるか
		var sideOver = wd.height()-sideArea.height();
		//固定を開始する位置 = サイドバーの座標＋はみ出す距離
		var starPoint = sideArea.offset().top + (-sideOver);
		//固定を解除する位置 = メインコンテンツの終点
		var breakPoint = sideArea.offset().top + mainH;
		
		wd.scroll(function() { //スクロール中の処理
			if(wd.height() < sideArea.height()){ //サイドメニューが画面より大きい場合
				if(starPoint < wd.scrollTop() && wd.scrollTop() + wd.height() < breakPoint){ //固定範囲内
					sideArea.css({"position": "fixed", "bottom": "0"}); 
				}else if(wd.scrollTop() + wd.height() >= breakPoint){ //固定解除位置を超えた時
					sideArea.css({"position": "absolute", "bottom": "0"});
				} else { //その他、上に戻った時
					sideArea.css("position", "static");
				}
			}else{ //サイドメニューが画面より小さい場合
				var sideBtm = wd.scrollTop() + sideArea.height(); //サイドメニューの終点
				if(mainArea.offset().top < wd.scrollTop() && sideBtm < breakPoint){ //固定範囲内
					sideArea.css({"position": "fixed", "top": "0"});
				}else if(sideBtm >= breakPoint){ //固定解除位置を超えた時
					//サイドバー固定場所（bottom指定すると不具合が出るのでtopからの固定位置を算出する）
					var fixedSide = mainH - sideH;
					sideArea.css({"position": "absolute", "top": fixedSide});
					} else {
						sideArea.css("position", "static");
					}
				}
		});
	} else if (default_width <= break_point) {
		sideWrap.css({"height": "auto","position": "static"});
	}
}

var snsFix = function() {
  var $fixElement = $('.moreList'); // 追従する要素
  if ($fixElement.length) {
  var baseFixPoint = $fixElement.offset().top; // 追従する要素の初期位置
  // 要素が追従する処理
    function fixFunction() {
        var windowScrolltop = $(window).scrollTop();
        // スクロールが初期位置を通過しているとき
        if(windowScrolltop >= baseFixPoint && windowScrolltop <= $(document).height() - $(window).height() - $(".footer").innerHeight()) {
          $fixElement.css({"top": windowScrolltop,"bottom":"initial"});
        } else if (windowScrolltop >= baseFixPoint && windowScrolltop >= $(document).height() - $(window).height() - $(".footer").innerHeight()) {
          $fixElement.css({"top": "initial","bottom":0});
        } else {
          $fixElement.css({"top": 20+"vh","bottom":"initial"});
        }
    }
    $(window).on('load scroll', function() {
      if (resize_width >= break_point) {
        fixFunction();
      }
    });
  }
}

